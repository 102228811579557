import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { MdArrowOutward } from 'react-icons/md'
import Spinner from 'react-bootstrap/Spinner'
import TooltipIcon from '../../TooltipIcon'

export default function PricingTeam({ region }) {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  return (
    <div className='main-pricing-team center-content'>
      <div
        className='center-content'
        style={{
          maxWidth: '580px',
        }}
      >
        <h1>Get started with VerifiedX today</h1>
        <a href='pricing' style={{ textDecoration: 'none', color: 'inherit' }}>
          <p
            style={{ textAlign: 'center', fontSize: '1.125rem', marginTop: 30 }}
          >
            View pricing plans &gt;
          </p>
        </a>
      </div>

      <div
        className='drop-shadow'
        style={{ backgroundColor: '#F8F8F8', borderRadius: 10 }}
      >
        <div style={{ marginTop: '30px' }}>
          <Card
            style={{
              padding: 16,
              border: 'none',
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ fontSize: '24px', fontFamily: 'SK Concretica' }}
              >
                <p>
                  <strong>
                    Protect Your Business with Advanced AI Security
                  </strong>
                </p>
              </Card.Title>
              <div>
                <ul
                  id='pricing-list'
                  style={{ listStyleType: 'none', padding: 0 }}
                >
                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Scam and phishing protection with 99.999% detection
                      accuracy
                    </span>

                    {/* <br />
                    <span style={{ fontSize: 16, marginLeft: 60 }}>
                      Shields your team from sophisticated scams and phishing
                      across all devices.
                    </span> */}
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Protection everywhere: in social media, emails, SMS, etc.{' '}
                      <TooltipIcon message='Test your messages and links with our Android and iOS apps. Android users can test in any app using our floating icon. A similar iOS solution is coming soon!' />
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Significant ROI: A $99 protection will save you hundreds
                      of thousands
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Prevent reputation damage and loss of customer trust
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Meet industry security and regulatory compliance standards
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Easily add, remove and manage team members
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Clear explanations of detected scams to educate your team
                      on phishing threats
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Protection on mobile, desktop and tablet
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />

                  <li
                    style={{
                      paddingBottom: 15,
                    }}
                  >
                    <span
                      style={{
                        fontSize: 20,
                      }}
                    >
                      24/7 priority support
                    </span>
                  </li>

                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#c4c4c4',
                      marginBottom: 15,
                      width: '100%',
                    }}
                  />
                </ul>
              </div>

              {!region ? (
                <Spinner animation='grow' />
              ) : (
                <>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    <span style={{ fontSize: 24 }}>
                      {region === 'GB' ? '£16.99' : '$19.99'}
                    </span>{' '}
                    per user / month billed quarterly
                  </p>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    <span style={{ fontSize: 24 }}>
                      {region === 'GB' ? '£19.99' : '$24.99'}
                    </span>{' '}
                    per user / month billed monthly
                  </p>
                </>
              )}

              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                *Minimum of 5 users required
              </p>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                *10+ users: additional 10% discount on the total price
              </p>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                *20+ users: 15% discount on the total price
              </p>

              <a
                href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
                style={{
                  textDecoration: 'none',
                  width: 'fit-content',
                }}
              >
                <div className='start-now-button'>
                  Start now{' '}
                  <span style={{ fontSize: 20 }}>
                    <MdArrowOutward size={20} />
                  </span>
                </div>
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}
