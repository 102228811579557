import React from 'react'
import QuestionsCard from '../../components/pricing/QuestionsCard'
import ContactForm from '../../components/Contact'

const ContactUs = () => {
  return (
    <div
      style={{
        paddingTop: '14.563rem',
        backgroundColor: '#e3e3e3',
      }}
    >
      <div
        className='center-content'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '90%',
        }}
      >
        <div
          style={{
            paddingTop: '7rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '768px',
          }}
        >
          <h1 style={{ fontSize: '64px' }}>Contact</h1>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.125rem',
              padding: '0px 60px',
            }}
          >
            Request a custom quote or give feedback on our product or service.
            We’ll get back to you asap.
          </p>
        </div>

        <ContactForm />

        <QuestionsCard
          marginTop='6.25rem'
          isReverse={false}
          image='https://res.cloudinary.com/dmdcur6t5/image/upload/v1733252469/contact/tb3mgt2kbxpo3vnbgzj3.png'
          heading='Millions of people and businesses lose billions of dollars every year to scams and phishing.'
          btnLink={process.env.REACT_APP_VERIFIEDX_HOME}
          btnText='Protect yourself now'
        />
        <QuestionsCard
          marginTop='6.25rem'
          isReverse={true}
          imageBgColor='#fff'
          image='https://res.cloudinary.com/dmdcur6t5/image/upload/v1733252361/contact/cocl1xcahoom2actx6ir.png'
          heading="Don't let yourself, your company, or a family member be one of them victims."
          btnLink={process.env.REACT_APP_VERIFIEDX_HOME}
          btnText='Try VerifiedX now'
        />
      </div>
    </div>
  )
}

export default ContactUs
