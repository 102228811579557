import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { MdArrowOutward } from 'react-icons/md'
import TooltipIcon from '../TooltipIcon'

const ROICalculator = ({ region }) => {
  const hasWindow = typeof window !== 'undefined'
  const [width, setWidth] = useState(0)
  const [numberOfEmployees, setNumberOfEmployees] = useState(5)
  const [selectedOption, setSelectedOption] = useState('Monthly')

  const monthlyTeamPrice = region === 'GB' ? 19.99 : 24.99
  const quarterlyTeamPrice = region === 'GB' ? 16.99 : 19.99

  const totalPricePerMonth = (numberOfEmployees * monthlyTeamPrice).toFixed(2)
  const totalPricePerQuarter = (
    numberOfEmployees *
    quarterlyTeamPrice *
    3
  ).toFixed(2)
  const constSavingPerMonth = 5 * numberOfEmployees * 100000
  const constSavingPerQuarter = 5 * numberOfEmployees * 100000 * 3

  // const totalMonthlyROI =
  //   constSavingPerMonth / (numberOfEmployees * region === 'GB' ? 22.3 : 19.99)
  // const totalQuarterlyROI =
  //   constSavingPerQuarter /
  //   (numberOfEmployees * region === 'GB' ? 26.24 : 24.99)
  const totalMonthlyROI = constSavingPerMonth / totalPricePerMonth
  const totalQuarterlyROI = constSavingPerQuarter / totalPricePerQuarter

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  useEffect(() => {
    if (hasWindow) {
      setWidth(window.innerWidth)
    }
  }, [hasWindow])

  // Handle change when a radio button is clicked
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
  }

  return (
    <div className='roi-team center-content'>
      <h1>Calculate Your Team's ROI with VerifiedX</h1>
      <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>
        Discover how much you can save by protecting your team against scams and
        phishing attacks.
      </p>

      <div style={{ marginTop: '30px' }}>
        <div
          style={{
            padding: 16,
            border: 'none',
          }}
        >
          <div
            className='mx-auto'
            style={{
              fontSize: '24px',
              fontFamily: 'SK Concretica',
              marginBottom: '4rem',
              maxWidth: 600,
            }}
          >
            <Form>
              <Form.Group className='mb-3'>
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Form.Label style={{ width: width > 500 ? 300 : '100%' }}>
                    Enter number of employees:
                  </Form.Label>
                  <Form.Control
                    type='number'
                    style={{ fontSize: 26, width: 'auto' }}
                    placeholder='Enter number of employees (minimum 5)'
                    value={numberOfEmployees}
                    onChange={(e) => setNumberOfEmployees(e.target.value)}
                    min={5}
                  />
                </div>

                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    gap: 10,
                    flexWrap: 'wrap',
                  }}
                >
                  <Form.Label style={{ width: 250 }}>
                    Billing Frequency:{' '}
                  </Form.Label>
                  <Form.Check
                    inline
                    label='Monthly'
                    name='group1'
                    type='radio'
                    id={`inline-radio-2`}
                    value='Monthly'
                    checked={selectedOption === 'Monthly'}
                    onChange={handleOptionChange}
                  />
                  <Form.Check
                    inline
                    label='Quarterly'
                    name='group1'
                    type='radio'
                    id={`inline-radio-2`}
                    value='Quarterly'
                    checked={selectedOption === 'Quarterly'}
                    onChange={handleOptionChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </div>

          <div className='center-content' style={{ marginBottom: 50 }}>
            {!region ? (
              <Spinner animation='grow' />
            ) : (
              <>
                <Container fluid='md'>
                  {selectedOption === 'Monthly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Total Price per Month
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          marginBottom: 10,
                          borderRadius: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          {`${
                            region === 'GB' ? '£' : '$'
                          }${totalPricePerMonth}`}
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Total Price per Quarter
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          {`${
                            region === 'GB' ? '£' : '$'
                          }${totalPricePerQuarter}`}
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col>
                      <p
                        style={{
                          fontSize: 16,
                          textAlign: 'left',
                          marginBottom: 0,
                        }}
                      >
                        &#x2713; High-Risk Phishing Attempts per Employee per
                        Month
                      </p>
                    </Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          textAlign: 'left',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}
                      >
                        5
                      </p>
                    </Col>
                    <div
                      style={{
                        height: 1,
                        backgroundColor: '#c4c4c4',
                        marginBottom: width > 768 ? 10 : 25,
                        width: '100%',
                      }}
                    />
                  </Row>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col>
                      <p
                        style={{
                          fontSize: 16,
                          textAlign: 'left',
                          marginBottom: 0,
                        }}
                      >
                        &#x2713; Total High-Risk Phishing Attempts per Month
                      </p>
                    </Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          textAlign: 'left',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}
                      >
                        {5 * numberOfEmployees}
                      </p>
                    </Col>
                    <div
                      style={{
                        height: 1,
                        backgroundColor: '#c4c4c4',
                        marginBottom: width > 768 ? 10 : 25,
                        width: '100%',
                      }}
                    />
                  </Row>
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col>
                      <p
                        style={{
                          fontSize: 16,
                          textAlign: 'left',
                          marginBottom: 0,
                        }}
                      >
                        &#x2713; Average Cost per Successful Attack{' '}
                        <TooltipIcon />
                      </p>
                    </Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 20,
                          textAlign: 'left',
                          fontWeight: 'bold',
                          marginBottom: 0,
                        }}
                      >
                        $100,000
                      </p>
                    </Col>
                    <div
                      style={{
                        height: 1,
                        backgroundColor: '#c4c4c4',
                        marginBottom: width > 768 ? 10 : 25,
                        width: '100%',
                      }}
                    />
                  </Row>

                  {selectedOption === 'Monthly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Potential Cost Savings per Month
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          ${numberWithCommas(constSavingPerMonth)}
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Potential Cost Savings per Quarter
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          ${numberWithCommas(constSavingPerQuarter)}
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}

                  {selectedOption === 'Monthly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Estimated ROI per Month
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          {numberWithCommas(Math.floor(totalMonthlyROI))}%
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                      <Col>
                        <p
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                            marginBottom: 0,
                          }}
                        >
                          &#x2713; Estimated ROI per Quarter
                        </p>
                      </Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                        }}
                      >
                        <p
                          style={{
                            fontSize: 20,
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: 0,
                          }}
                        >
                          {numberWithCommas(Math.floor(totalQuarterlyROI))}%
                        </p>
                      </Col>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: '#c4c4c4',
                          marginBottom: width > 768 ? 10 : 25,
                          width: '100%',
                        }}
                      />
                    </Row>
                  )}
                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col>
                      <p
                        style={{
                          fontSize: 16,
                          textAlign: 'left',
                          marginBottom: 0,
                        }}
                      >
                        &#x2713; Plus, avoid reputation damage and maintain
                        customer trust.
                      </p>
                    </Col>
                  </Row>

                  <Row style={{ display: 'flex', alignItems: 'center' }}>
                    <Col style={{ marginTop: 30 }}>
                      <p
                        style={{
                          fontSize: 16,
                          textAlign: 'left',
                          marginBottom: 0,
                        }}
                      >
                        * These estimates are based on industry averages and are
                        for illustrative purposes only. Actual results will vary
                        based on the actual number of threats your employees
                        will face and your specific industry.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </>
            )}
          </div>

          <a
            href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
            style={{
              marginTop: 30,
              textDecoration: 'none',
              width: 'fit-content',
            }}
          >
            <div
              className='start-now-button'
              style={{
                fontFamily: 'SK Concretica, sans-serif',
              }}
            >
              PROTECT MY TEAM NOW{' '}
              <span style={{ fontSize: 20 }}>
                <MdArrowOutward size={20} />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ROICalculator
